@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: black; 
}

h4, p,
a{
  color: black;
  text-decoration: none;
}
h1 {
  background-color: gray;
  text-decoration-color: white;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: gray;
  color: black;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: white;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transition: 0.3s;
}

.cart-container {
  padding: 2rem 4rem;
}
.cart-container h2{
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: white;
  
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
  

  
}
.cart-container .titles h3{
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  
}
.cart-item, .cart-container .titles{
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-container .cart-product-price, .cart-product-quantity {
  color: white;
}
.cart-container .cart-product-total-price {
  color: white;
}
.cart-item{
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title{
  padding-left: 0.5rem;
}
.cart-container .titles .total{
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: flex;
  color: white;
}
.cart-item .cart-product img{
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}
.cart-item .cart-product h3{
  font-weight: 400;
}
.cart-item .cart-product button{
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover{
  color: red;
}
.cart-product-quantity{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}
.cart-product-quantity button{
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  color: white;
}
.cart-product-quantity .count{
  padding: 0.7rem 0;
}
.cart-product-total-price{
  justify-self: right;
  padding-right: 0.5rem;
  font-weight: 700;
}
.cart-summary{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary span{
  color: white;
}
.cart-summary .clear-cart{
  width: 130px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;

}
.cart-checkout .subtotal{
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .subtotal .amount{
  font-weight: 700;
}
.cart-checkout p{
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button{
  width: 100%;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #4b70e2;
    color: white;
    letter-spacing: 1.15px;
}
.continue-shopping, .start-shopping{
  margin-top: 1rem;
}
.continue-shopping a, .start-shopping a{
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span, .start-shopping a span{
  margin-left: 0.5rem;
}
.cart-empty{
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}