/* DonationCounter.css */
.donation-counter {
  font-family: Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  padding: 20px;
  position: relative;
  top: 100px; /* Adjust this value as needed */
  margin-bottom: 50px; /* Adjust the value to provide bottom margin */
}

.counter {
  font-size: 48px;
  color: #28a745;
  font-weight: bold;
}
