.into-img {
    width: 100vw;
    height: 170vh;
    overflow: hidden;
    padding-top: 900px;
}
.full-screen {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    padding: auto;
}


.hero{
    height: 100%;
    width: 100%;
}
.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 52.5%;
    left: 50%;
    text-align: center;
    width: 85%;
    padding-bottom: 8px;
    padding-top: 80px;
    top: 60%;
}
.hero .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
    color: white;
    background-color: transparent;
    
}
.hero .content h2 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
    color: white;
    background-color: transparent;
  
}
.hero .content p{
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
    background-color: transparent;
    
}
.content .btn {
    margin: 1rem 0.2rem;
}

@media screen and (max-width: 670px) {
    .hero .content {
        top: 130%;
        
    }

    .hero .content h1 {
        font-size: 3rem;
        background-color: transparent;
    }
    .hero .content p {
        font-size: 1.5rem;

    }
    .hero .into-img {
        width: 99vw;
        max-width: 100%;
        height: 300vh;
        padding-top: 1450px;
        

    }


}