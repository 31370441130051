.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 10;
    background-color: gray;
}
.header-bg{
    background-color: gray;
    transition: 0.5s;
}
.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}
.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    background-color: transparent;
}
.hamburger {
    display: none;
}
.bag-quantity{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    background: black;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
}
.not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: (rgb(68, 68, 68));
    min-height : 80vh;
}
@media screen and (max-width: 1040px) {
    .header h1 {
        background-color: transparent;
        color: white;
    }
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.active{
        left: 0;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;
    }

    .hamburger {
        display: initial;
        
    }
}
.home-container {
    padding: 2rem 4rem;
}
.home-container h2{
    font-size: 40px;
    font-weight: 400;
    text-align: center;
}
.products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.product{
    width: 350px;
    max-width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    padding: 1rem;
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 2px 2px 5px rgba(94, 104, 121, 0.3);
    border-radius: 15px;

}
.product img{
    width: 100%;
    height: 60%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}
.product h3{
    font-size: 25px;
    font-weight: 400;
    color: white;
}
.product .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product .details .price {
    font-size: 20px;
    font-weight: 700;
    color: white;
}
.product button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    margin-top: 2rem;
    font-weight: 400;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #4b70e2;
    color: white;
    letter-spacing: 1.15px;
}