
.team-item {
    margin: 20px;
    background-color: black;
}
.team-item {
    margin: 15px 0;
    padding: 50px 20px 40px 20px;
    border-radius: 8px 8px;

}
.team-item h1 {
    margin: 20px 0 1px 0 !important;
    background-color: black;
    color: white;
    
}
.team-item p {
    display: block;
    margin-bottom: 20px;
    color: white;
}
.team-item img{
    max-width: 140px;
    padding: 6px 6px;
    background-color: white;
    border-radius: 50%;

}
.team-item .matthias {
    padding: 50px 20px 40px 20px;
    border: 2px solid white;
}
.team-item .rayirth {
    padding: 50px 20px 40px 20px;
    border: 2px solid white;
}
.team-item .daanish {
    padding: 50px 20px 40px 20px;
    border: 2px solid white;
}
.team-item .eric {
    padding: 50px 20px 40px 20px;
    border: 2px solid white;
}
.headerss h1 {
    padding-top: 110px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 4rem;
}



@media screen and (max-width: 640px) {
    .team-item {
        margin: 15px 0;
        padding: 50px 20px 40px 20px;
        border-radius: 8px 8px;

    }
    .team-item h1 {
        margin: 20px 0 1px 0 !important;
        background-color: black;
        color: white;
        
    }
    .team-item p {
        display: block;
        margin-bottom: 20px;
        color: white;
    }
    .team-item img{
        max-width: 140px;
        padding: 6px 6px;
        background-color: white;
        border-radius: 50%;

    }
    .team-item .matthias {
        padding: 50px 20px 40px 20px;
        border: 2px solid white;
    }
    .team-item .rayirth {
        padding: 50px 20px 40px 20px;
        border: 2px solid white;
    }
    .team-item .daanish {
        padding: 50px 20px 40px 20px;
        border: 2px solid white;
    }
    .team-item .eric {
        padding: 50px 20px 40px 20px;
        border: 2px solid white;
    }
}